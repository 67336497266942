import React from 'react';
import UrlList from '../containers/UrlList';

const UrlListView = () => (
  <div>
    <UrlList />
  </div>
);

export default UrlListView;
