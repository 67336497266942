import React from 'react';
import ServiceList from '../containers/ServiceList';

const ServiceListView = () => (
  <div>
    <ServiceList />
  </div>
);

export default ServiceListView;
